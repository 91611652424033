<template>
  <div class="price-container" id="price" ref="richTextContainer">
    <h1 class="title">{{ content.title }}</h1>
    <p
      class="des"
      v-if="content.des"
      v-html="content.des.replace(/\./g, '.<br/>')"
    ></p>
    <div class="select-local" v-if="content.moreLocal || showMoreLocal">
      <div class="local-title">Please select the exchange rate</div>
      <el-select
        v-model="country"
        placeholder="Please select language"
        style="width: 360px"
      >
        <el-option
          v-for="(item, index) in contryList"
          :key="index"
          :label="item.key"
          :value="item.value"
        />
      </el-select>
    </div>
    <div
      class="price-list"
      v-if="showLoading"
      :class="[
        `price-list-${productList.length}`,
        `price-list-${productList.length > 10 ? 'more' : productList.length}`,
      ]"
    >
      <div
        class="price-setup"
        v-for="(item, idx) in priceSetup"
        v-show="productList[idx]"
        :key="idx"
      >
        <div style="display: flex; align-items: center; flex-wrap: wrap">
          <div
            v-if="productList[idx]"
            v-html="
              item.price
                .replace(/{money}/g, moneyShow(productList[idx]))
                .replace(/{currencyUnit}/g, '')
                .replace(/{useTime}/g, productList[idx].useTime)
            "
          ></div>
          <div class="tag">{{ item.tag }}</div>
        </div>
        <ul>
          <li class="des" v-for="line in item.desList" :key="line">
            <span
              v-html="
                line
                  .replace(
                    /{quota}/g,
                    (productList[idx] &&
                      productList[idx] &&
                      productList[idx].config &&
                      productList[idx].config.quota) ||
                      '1800'
                  )
                  .replace(
                    /{useTime}/g,
                    (productList[idx] && productList[idx].useTime) || 'month'
                  )
              "
            ></span>
          </li>
        </ul>
        <button
          :style="`cursor: ${loading ? 'wait' : ''}`"
          v-show="productList.length"
          class="pay-button"
          @click="handlePay(idx)"
        >
          {{ item.buttonTxt }}
        </button>
        <p
          class="tip"
          v-if="productList[idx]"
          v-html="
            item.tip
              .replace(/{money}/g, moneyShow(productList[idx]))
              .replace(/{price}/g, moneyShow(productList[idx], 'price'))
              .replace(/{useTime}/g, productList[idx].useTime || 'month')
              .replace(/monthly/g, productList[idx].useTimes || 'monthly')
              .replace(/{useTimes}/g, productList[idx].useTimes || 'monthly')
              .replace(/{currencyUnit}/g, '')
          "
        ></p>
      </div>
    </div>
  </div>
</template>

<script>
import Popup from "./ui/Pop-up.vue";

const currencyMap = {
  RUB: "₽",
  USD: "US$",
  VND: "VND",
  PHP: "₱",
  ZAR: "R",
  GBP: "£",
  SGD: "S$",
  NZD: "NZD$",
  EUR: "€",
  INR: "₹",
  CAD: "C$",
  AUD: "AUD$",
  IDR: "Rp",
  MYR: "RM",
  KRW: "₩",
  HKD: "HK$",
  TWD: "NT$",
  BRL: "R$",
  THB: "฿",
  HKD: "HKD",
  TRY: "₺",
  AED: "د.إ",
};
const durationUnitTypes = {
  1: "hourly",
  2: "dayly",
  3: "weekly",
  4: "monthly",
  5: "yearly",
  100: "Lifetime",
};
const offerTypePrices = {
  0: "price",
  1: "tryoutPrice",
  2: "firstDiscountPrice",
};

const durationUnitType = {
  1: "hour",
  2: "day",
  3: "week",
  4: "month",
  5: "year",
  100: "Lifetime",
};

import { getProductList, createOrder, payOrder } from "@/api/pay";
import { $extractDomain } from "@/utils/index";
export default {
  name: "Banner",
  props: {
    payUrl: {
      type: Object,
      required: true,
      default: () => {},
    },
    content: {
      type: Object,
      required: true,
      default: () => {},
    },
    defaultProductList: {
      type: Array,
      default: () => {
        return [
          {
            money: "49.8",
            price: "49.8",
            useTime: "1-month",
          },
          {
            money: "0.99",
            price: "49.8",
            useTime: "2-days",
          },
        ];
      },
    },
  },
  components: { Popup },
  computed: {
    duration() {
      return this.$t("duration");
    },
    lang() {
      let localLang = localStorage.getItem("lang") || "";
      let lang = this.$route.query.lang || localLang || "en";
      return lang;
    },
    contryList() {
      return [
        { key: "USD", value: "1" },
        { key: "AUD", value: "1.48" },
        { key: "NZD", value: "1.61" },
        { key: "EUR", value: "0.90" },
        { key: "HKD", value: "7.79" },
      ];
    },
  },
  data() {
    return {
      showMoreLocal: false,
      country: "1",
      loading: false,
      showLoading: false,
      priceSetup: [],
      //  审核马甲的订阅/一次性套餐可能顺序不同，按实际文案顺序调整
      productList: [],
    };
  },
  mounted() {
    this.$nextTick(() => {
      let mainPart = $extractDomain(window.location.hostname);
      let channel =
        this.$route.query.channel || localStorage.getItem("channel");
      if (channel) {
        mainPart = `${mainPart}_${channel}`;
        localStorage.setItem("channel", channel);
      } else {
        let localLang = localStorage.getItem("lang") || "";
        let lang = this.$route.query.lang || localLang || "en";
        // if (lang !== "en") {
        //   mainPart = `${mainPart}_${lang}`;
        // }
      }
      let siteInfo = sessionStorage.getItem("siteInfo");
      if (siteInfo) {
        siteInfo = JSON.parse(siteInfo);
        let localLang = localStorage.getItem("lang") || "";
        let lang = this.$route.query.lang || localLang || "en";
        if (siteInfo.data.common.en) {
          if (lang === "ph") lang = "en";
          siteInfo.data.common = siteInfo.data.common[lang];
        }
        this.showMoreLocal = siteInfo.data?.common?.NavBar?.moreLocal || false;
      }
      getProductList({ code: mainPart, extend: {} })
        .then((result) => {
          const contentPriceList = JSON.parse(
            JSON.stringify(this.content.priceSetup)
          );
          let priceTime = '<span class="medium-bold">/{useTime}</span>';
          this.priceSetup = contentPriceList.map((res) => {
            if (!res.price.includes("{useTime}")) {
              res.price = res.price + priceTime;
            }
            res.tip = res.tip
              .replace(/monthly/g, "{useTimes}")
              .replace(/bulanan/g, "{useTimes}")
              .replace(/setiap bulan/g, "{useTimes}")
              .replace(/ежемесячно/g, "{useTimes}")
              .replace(/еженедельно/g, "{useTimes}")
              .replace(/hàng tháng/g, "{useTimes}");
            return res;
          });
          if (result.result && result.result.length) {
            this.productList = result.result.map(this.useFillPriceInfo);
          } else {
            this.productList = this.defaultProductList;
          }
          if (this.productList.length > this.priceSetup.length) {
            const priceItems = {
              oneOff: this.priceSetup.find(
                (res) => (res.type || res.buttonTxt) === "Pay"
              ),
              subscription: this.priceSetup.find(
                (res) => (res.type || res.buttonTxt) === "Trial Now"
              ),
            };
            this.priceSetup = this.productList.map((res) => {
              if (res.offerType !== 0) {
                return priceItems.subscription;
              }
              return priceItems.oneOff;
            });
          }
          this.$nextTick(() => {
            this.addClickEventToElement();
          });
          this.showLoading = true;
        })
        .catch((e) => {
          this.productList = this.defaultProductList;
        });
    });
    if (this.$route.hash) {
      this.$nextTick(() => {
        document.querySelector(this.$route.hash) &&
          document.querySelector(this.$route.hash).scrollIntoView();
      });
    }
  },
  methods: {
    useFillPriceInfo(item) {
      item.money = item[offerTypePrices[item.offerType]];
      let unit = "durationUnit";
      let duration = "duration";
      if (item.offerType === 1) {
        unit = "tryoutDurationUnit";
        duration = "tryoutDuration";
      }
      const durationUnit = item[unit];
      const durationDate = item[duration];
      item.useTime = this.duration[durationUnitType[durationUnit]];
      if (durationDate === 100) {
        item.useTime = this.$t(`duration.LifeTime`);
      } else {
        item.useTime = durationDate + "-" + item.useTime;
      }
      item.useTimes = this.duration[durationUnitTypes[item.durationUnit]];
      if (durationDate === 100) {
        item.useTimes = this.$t(`duration.LifeTime`);
      }
      item.currencyUnit = currencyMap[item.currency];
      if (item.config) {
        try {
          item.config = JSON.parse(item.config);
        } catch (error) {
          item.config = {};
        }
      } else {
        item.config = {};
      }
      return item;
    },
    roundNumber(num) {
      var roundedNum = Math.ceil(num * 100) / 100; // Round the number to two decimal places
      var result = Math.floor(roundedNum); // Get the integer part of the number
      return Number(Math.floor(result) + 0.99).toFixed(2);
    },
    moneyShow(item, type = "money") {
      if (this.content.moreLocal || this.showMoreLocal) {
        const local = this.contryList.find((res) => res.value === this.country);
        let money = Number(Number(item[type]) * Number(this.country)).toFixed(
          2
        );
        if (local.key === "NZD") {
          money = this.roundNumber(Number(money));
        }
        return `${currencyMap[local.key]} ${money}`;
      }
      return `${item.currencyUnit || "$"} ${item[type]}`;
    },
    addClickEventToElement() {
      const localLink =
        this.$refs.richTextContainer.querySelector("#accountLink");
      if (localLink) {
        localLink.addEventListener("click", this.goAccount);
      }
    },
    goAccount() {
      const has = localStorage.getItem("user_email");
      if (!has) {
        this.$store.commit("OPEN_LOGIN", true);
        sessionStorage.setItem("pushSta", 0);
      } else {
        this.$router.push({ path: "/sub/account" });
        this.$router.go(0);
      }
    },
    async handlePay(idx) {
      if (!this.productList[idx].productsPriceList) {
        return this.nopay();
      }
      if (this.loading) return;
      this.loading = true;
      try {
        const params = {
          productsId: this.productList[idx].id,
          productsPriceId: "0",
          successUrl: `${this.$origin("/sub/payresult")}`, //  支付成功url
          cancelUrl: `${this.$origin("/sub/payresult")}`, //  取消支付链接
          userExtend: {},
          transparentExtend: {},
          orderAdInfoExtend: {},
          marketingChannel: "seo",
        };
        const userState = localStorage.getItem("user_email");
        if (userState) {
          params.email = userState;
        }
        const order = await createOrder(params);

        const result = await payOrder({
          orderId: order.obj.orderId,
          payExtend: {},
        });
        this.loading = false;
        window.location.href = result.obj.redirectUrl;
      } catch (e) {
        this.loading = false;
        alert(e.msg || e);
      }
    },
    nopay() {
      alert("Please wait patiently for us to access the payment");
    },
  },
};
</script>

<style scoped lang="scss">
@media (min-width: 981px) {
  .price-container {
    width: 1440px;
    margin: 0 auto;
    padding: 50px 0px;
    padding-bottom: 50px;
    text-align: center;

    .title {
      margin-bottom: 24px;
      font-size: 36px;
      font-family: Roboto-Medium, Roboto;
      color: var(--theme-primary-black);
      line-height: 42px;
      text-align: center;
    }
    .select-local {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
      .local-title {
        margin-bottom: 10px;
        font-weight: bold;
        font-size: 18px;
        text-align: left;
      }
    }
    .des {
      font-size: 16px;
      font-family: Roboto-Regular, Roboto;
      font-weight: 400;
      color: var(--theme-primary-black);
      line-height: 24px;
      margin-bottom: 44px;
    }

    &::v-deep .high-bold {
      font-size: 24px;
      font-weight: bold;
      color: var(--theme-primary-black);
      line-height: 28px;
    }

    &::v-deep .medium-bold {
      margin-top: 10px;
      font-size: 14px;
      color: var(--theme-primary-tiny);
      line-height: 18px;
    }

    .price-list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
      text-align: left;
      &.price-list-3 {
        grid-template-columns: repeat(3, 1fr);
        .price-setup {
          min-width: 300px;
        }
      }
      &.price-list-1 {
        grid-template-columns: repeat(1, 1fr);
      }
      &.price-list-13 {
        grid-template-columns: repeat(3, 1fr);
      }
      &.price-list-more {
        grid-template-columns: repeat(3, 1fr);
      }
      &.price-list-5 {
        grid-template-columns: repeat(3, 1fr);
        .price-setup {
          min-width: 300px;
        }
      }
      &.price-list-4 {
        grid-template-columns: repeat(3, 1fr);
        .price-setup {
          min-width: 300px;
        }
      }
      .price-setup {
        min-width: 500px;
        margin: 0 auto;
        padding: 32px;
        box-sizing: border-box;
        border: 4px solid #f2f8ff;
        max-width: 500px;
        width: 100%;

        .tag {
          margin-left: 8px;
          padding: 2px 10px;
          font-size: 14px;
          font-weight: 400;
          background: var(--theme-primary-price-tag-bg);
          color: var(--theme-primary-price-tag);
          border-radius: 12px;
          line-height: 20px;
        }

        ul {
          margin: 16px 0 24px;

          li {
            margin-bottom: 8px;
          }
        }

        .pay-button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 222px;
          height: 48px;
          font-size: 20px;
          font-weight: bold;
          background: var(--theme-primary-suffix);
          color: var(--theme-primary-white);
          border-radius: 30px;
          border: none;
          line-height: 24px;
          outline: none;

          &:hover {
            opacity: 0.8;
          }
        }

        .tip {
          margin-top: 10px;
          font-size: 14px;
          color: var(--theme-primary-tiny);
          line-height: 18px;
        }
      }
    }
  }
}

@media (max-width: 981px) {
  .price-container {
    width: 100%;
    margin: 1rem auto;
    text-align: center;
    padding: 0.32rem;
    box-sizing: border-box;

    .title {
      margin-bottom: 24px;
      font-size: 36px;
      font-family: Roboto-Medium, Roboto;
      color: var(--theme-primary-black);
      line-height: 42px;
      text-align: center;
    }
    .select-local {
      margin-bottom: 0.4rem;
    }
    .des {
      margin-bottom: 44px;
      font-size: 16px;
      font-weight: 400;
      font-family: Roboto-Regular, Roboto;
      color: var(--theme-primary-black);
      line-height: 24px;
    }

    &::v-deep .high-bold {
      font-size: 24px;
      font-weight: bold;
      color: var(--theme-primary-black);
      line-height: 28px;
    }

    &::v-deep .medium-bold {
      margin-top: 10px;
      font-size: 14px;
      color: var(--theme-primary-tiny);
      line-height: 18px;
    }

    .price-list {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 20px;
      text-align: left;

      .price-setup {
        min-width: 100%;
        margin: 0 auto;
        padding: 32px;
        box-sizing: border-box;
        border: 4px solid #f2f8ff;

        .tag {
          margin-left: 8px;
          padding: 2px 10px;
          font-size: 14px;
          font-weight: 400;
          background: var(--theme-primary-price-tag-bg);
          color: var(--theme-primary-price-tag);
          border-radius: 12px;
          line-height: 20px;
        }

        ul {
          margin-top: 16px;
          margin-bottom: 24px;

          li {
            margin-bottom: 8px;
          }
        }

        .pay-button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 222px;
          height: 48px;
          font-size: 20px;
          font-weight: bold;
          background: var(--theme-primary-suffix);
          color: var(--theme-primary-title);
          line-height: 24px;
          border-radius: 30px;
          border: none;
          outline: none !important;
        }

        .tip {
          margin-top: 10px;
          font-size: 14px;
          color: var(--theme-primary-tiny);
          line-height: 18px;
        }
      }
    }
  }
}
</style>
